/*eslint no-console: ["off"] */
// import Vue from "vue";
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";
import { httpClient} from "@/services/http-client";
import { appSessionStorage, localStorageKey } from "@/utils/storage";
import inspect from "util-inspect"
import assert from 'assert'

export const heraclesLogPath = '/logs';

const Logger = class {
    isDevelopment = process.env.VUE_APP_NODE_ENV === "development";

    info = (message, event = null) => {
        const phoneNumber = appSessionStorage.getItem(localStorageKey.nationalNumber) || 'N/A';
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId) || 'N/A';
        const logMessage = `[INFO] [P#: ${phoneNumber} | S#: ${sessionId}] ${message}`;

        if (this.isDevelopment) {
            console.info(`sessionId: ${appSessionStorage.getItem(localStorageKey.sessionId)} phoneNumber:${appSessionStorage.getItem(localStorageKey.nationalNumber)} message:${logMessage}`);
        }
        this.sendToBackend(logMessage, event);
    };

    error = (message, event = null, error = null) => {
        const phoneNumber = appSessionStorage.getItem(localStorageKey.nationalNumber) || 'N/A';
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
        const now = Date.now();
        let sentryLogMessage = `[ERROR] ${window.location.host} [P#: ${phoneNumber} | S#: ${sessionId ? sessionId : `N/A`}] ${message}`
        let logMessage = `${sentryLogMessage}`

        if (error) {
            sentryLogMessage += sessionId ? ` see aws logs for inspect(error) output. search for {${now}}` : ` error: $${inspect(error)}`
            logMessage += ` {${now}} error: $${inspect(error)}\``
        }

        if (this.isDevelopment) {
            console.error("isDevelopment",logMessage);
        } else {
            console.error("sentryLogMessage",sentryLogMessage);
            // Sentry.captureException(sentryLogMessage);
        }
        this.sendToBackend(logMessage, event);
    };

    sendToBackend = async (message, event) => {
        if (!message.trim()) { // Don't send empty messages
            return
        }
        try {
            const sessionId = appSessionStorage.getItem(localStorageKey.sessionId);
            if (!sessionId) {
                console.info('[INFO] Session ID not set yet');
                return
            }

            const phoneNumber = appSessionStorage.getItem(localStorageKey.nationalNumber);
            message = "Logged in Phone number:".concat(phoneNumber).concat("message").concat(message)
            let postBody = {
                sessionId: sessionId,
                messages: [{
                    timestamp: Date.now(),
                    message: message.substring(0, 1024*24) // Limited to 32768 chars by AWS
                }],
            };

            if (event) {
                assert(event.eventName, `message: ${message}, event object missing eventName`)
                postBody.events = [{
                    timestamp: Date.now(),
                    eventJson: JSON.stringify(event),
                }]
            }

            await httpClient.post(heraclesLogPath, postBody);
        } catch(error) {
            console.error(`[ERROR] Could not send log: ${JSON.stringify(error)}`)
        }
    };
};

const logger = new Logger();

export { logger };
