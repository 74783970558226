<template>
    <div>
        <div class="connect-top-level">
            <div
                id="connectContainer"
                class="connect-iframe-container"
            />
            <div
                id="contactDetails"
                class="connect-contact-details"
            >
                <h2>{{ callerName }}</h2>
                <div>
                    <h3 v-if="isRecording">
                        Recording
                    </h3>
                    <h3 v-else>
                        NOT Recording
                    </h3>
                    <button
                        @click="pauseRecording"
                        :disabled="!contactId"
                    >
                        Stop Recording
                    </button>
                    <button
                        @click="resumeRecording"
                        :disabled="!contactId"
                    >
                        Resume Recording
                    </button>
                </div>
                <a
                    v-if="zendeskTicketUrl"
                    :href="zendeskTicketUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <b>Zendesk Ticket</b>
                </a>
                <ul class="connect-contact-attributes-list">
                    <li
                        v-for="(value, key) in callerAttributes"
                        :key="key"
                    >
                        <b>{{ key }}</b>: {{ value }}
                    </li>
                </ul>
            </div>
        </div>
        <p class="connect-footer">
            If the softphone fails to load, please
            <a
                href="https://aven.my.connect.aws/"
                target="_blank"
                rel="noopener noreferrer"
            >log in here</a>
            and then reload this page
        </p>
    </div>
</template>

<script>
    import 'amazon-connect-streams';
    import { logger } from "@/utils/logger";
    import { suspendCallRecording, resumeCallRecording } from "@/services/amazonConnectApi";
    /*global connect*/
    /*eslint no-undef: "error"*/
    const CALLER_ATTRIBUTE_NAMES = ['personType', 'creditCardCustomerId', 'applicantId', 'loanApplicationId', 'loanApplicationStatus', 'phoneNumber', 'email', 'dateOfBirth', 'ssnLastFour', 'addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressCountry', 'addressPostalCode', 'coreCardCustomerId', 'coreCardAccountNumber', 'cardType', 'cardProductType', 'redactedCardNumber', 'accountCreationDate', 'systemAccountStatus', 'manualAccountStatus' , 'dqDays', 'applicationCreatedAt', 'applicationReturnUrl', 'documentUploadUrl', 'reviewUrl', 'notarized'];

    export default {
        name: 'AmazonConnect',
        data: function () {
            return {
                callerName: '',
                zendeskTicketUrl: '',
                contactId: null,
                callerAttributes: {},
                isRecording: false,
            };
        },
        methods: {
            setContactDetails: function(contact) {
                this.clearContactDetails();
                const attributeMap = contact.getAttributes();
                this.contactId = contact.getContactId();

                const firstName = attributeMap['firstName']['value'];
                const lastName = attributeMap['lastName']['value'];
                if (firstName || lastName) {
                    this.callerName = `${firstName} ${lastName}`;
                }
                this.zendeskTicketUrl = attributeMap['zendeskUrl']['value'];
                for (const attributeName of CALLER_ATTRIBUTE_NAMES) {
                    try {
                        const attributeValue = attributeMap[attributeName]['value'];
                        if (attributeValue) {
                            this.callerAttributes[attributeName] = attributeValue;
                        }
                    } catch (e) {
                        logger.error(`Failed to find contact attribute ${attributeName}. Error: ${e}`);
                    }
                }
            },
            clearContactDetails: function() {
                this.callerName = '';
                this.zendeskTicketUrl = '';
                this.callerAttributes = {};
                this.contactId = null;
                this.isRecording = false
            },
            pauseRecording: async function() {
                try {
                    const result = await suspendCallRecording(this.contactId)
                    console.log(result)
                    if (result.data.success) {
                        this.isRecording = false
                    } else {
                        throw new Error('Server error while pausing recording.');
                    }

                } catch (e) {
                    logger.info(e);
                    alert(`Network error while pausing recording.`);
                }
            },
            resumeRecording: async function() {
                try {
                    const result = await resumeCallRecording(this.contactId)
                    if (result.data.success) {
                        this.isRecording = true
                    } else {
                        throw new Error('Server error while resuming recording.');
                    }
                } catch (e) {
                    logger.info(e);
                    alert(`Network error while resuming recording.`);
                }
            },
        },
        mounted: function() {
            connect.core.initCCP(document.getElementById('connectContainer'), {
                ccpUrl: 'https://aven.my.connect.aws/ccp-v2',
                loginPopup: true,
                softphone: {
                    allowFramedSoftphone: true
                }
            });

            connect.contact(contact => {
                contact.onIncoming(contact => {
                    logger.info(`onIncoming ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onRefresh(contact => {
                    logger.info(`onRefresh ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onAccepted(contact => {
                    logger.info(`onAccepted ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onEnded(contact => {
                    logger.info(`onEnded ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onDestroy(contact => {
                    logger.info(`onDestroy ${contact.getContactId()}`);
                    this.clearContactDetails();
                });

                contact.onACW(contact => {
                    logger.info(`onACW ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onConnecting(contact => {
                    logger.info(`onConnecting ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onPending(contact => {
                    logger.info(`onPending ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onMissed(contact => {
                    logger.info(`onMissed ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                });

                contact.onConnected(contact => {
                    logger.info(`onConnected ${contact.getContactId()}`);
                    this.setContactDetails(contact);
                    this.isRecording = true
                });
            });
            this.clearContactDetails();
        }
    }
</script>

<style scoped>
    .connect-top-level {
        display: flex;
    }
    .connect-iframe-container {
        height: 550px;
        flex: 50%;
        padding: 10px;
    }
    .connect-contact-details {
        flex: 50%;
        padding: 10px;
    }
    .connect-contact-attributes-list {
        list-style-type:none;
        padding-left: 0px;
    }
    .connect-footer {
        padding: 10px;
    }
</style>