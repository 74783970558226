import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { appSessionStorage, localStorageKey } from "@/utils/storage";

const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 30000,
};

const httpClient: AxiosInstance = axios.create(config);

const authInterceptor = (request: AxiosRequestConfig) => {
  /** add auth token */
  const accessToken = appSessionStorage.getItem(localStorageKey.jwtToken);
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Custom header for SessionJWT Authentication strategy
  const sessionAccessToken = appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
  if (sessionAccessToken) {
    request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
  }

  return request;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use((response) => {
  return response;
});

export { httpClient };
