import axios from 'axios';

const axiosBackend = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

export const suspendCallRecording = async (contactId: string) => {
    return await axiosBackend.post('/amazonConnect/suspendCallRecording', {
        contactId,
    });
}

export const resumeCallRecording = async (contactId: string) => {
    return await axiosBackend.post('/amazonConnect/resumeCallRecording', {
        contactId,
    });
}
